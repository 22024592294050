<template>
    <div class="mdc-chip-set mdc-chip-set--filter" :class="chip_key" role="grid">
        <div class="mdc-chip" :class="chip_key" role="row">
            <div class="mdc-chip__ripple"></div>
            <span class="mdc-chip__checkmark" >
            <svg class="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                <path class="mdc-chip__checkmark-path" fill="none" stroke="#00ADD9" 
                    d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
            </svg>
            </span>
            <span role="gridcell">
            <span role="checkbox" tabindex="0" aria-checked="false" class="mdc-chip__primary-action">
                <span class="mdc-chip__text">{{chip_name}} <span :data-order="chip_number" class="mdc-chip__text chip-text-bold reorder-id-after-drag-event">{{chip_number}}</span></span>
                <i class="material-icons mdc-chip__icon icon-dots">more_vert</i>
            </span>
            </span>
        </div>
    </div>
</template>



<script>
  import {MDCChipSet, MDCChip} from '@material/chips';

  export default {
    name: 'Chips',
    props: {
      chip_name: {type: String},
      chip_number: {type: Number},
      chip_key: {type: String},
      chip_state: {type: Boolean}
    },
    data() {
      return {
      };
    },
    mounted() {
        new MDCChipSet(document.querySelector('.mdc-chip-set' + '.' + this.chip_key));
        const chip = new MDCChip(document.querySelector('.mdc-chip' + '.' + this.chip_key));

        chip.selected = this.chip_state

        chip.ripple.unbounded = true;
      
        chip.ripple.getDefaultFoundation().adapter.registerInteractionHandler('click', () => {
            chip.selected = !chip.selected ? true : chip.selected = false;
            this.$emit('_emit_method_chip', [chip.selected, this.chip_key]);
        })

    },
    methods: {
    }
  }
</script>



<style lang="scss" scoped>
  @use "@material/chips/mdc-chips";

  .mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
    width: 0;
    height: 0;
  }
  .mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
    transform: none;
  }
  .mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  .icon-dots {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.38);
    margin-left: 14px;
  }

  .chip-text-bold {
    font-weight: 700;
    color: #000000 60%;
  }

  .tools-fixed .mdc-chip {
    background-color: #F3F4F7;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 20px;
  }

  .tools .mdc-chip {
    background-color: #FFFF;
    color: #00000B;
    border: lightgrey 1px solid;
    margin-bottom: 20px;
  }

</style>
