<template>
  <div class="mdc-layout-grid__inner" id="preferences">
    <div class="mdc-layout-grid__cell--span-1"></div>
    <div class="mdc-layout-grid__cell--span-10">
      <h3>Preferencias</h3>
      <h4 id="title-tools" class="title-margin">Campos Visibles en Herramientas</h4>
      <div disabled="disabled" class="chips-box">
        <div v-for="(item, index) in chip_prueba" :key="'A'+index" class="tools-fixed">
            <Chips class="item" :chip_state="item.chip_state" :chip_name="item.name" :chip_number="item.id" :chip_key="item.key" />
        </div>
      </div>

      <draggable id="preferences-dragable" ghost-class="ghost" class="chips-box chips-box-bottom list-group"  :component-data="getComponentData()">
        <div v-for="(item, index) in _list" :key="index" class="tools list-group-item">
          <Chips @_emit_method_chip="_chipValue" :chip_state="item.chip_state" :chip_name="item.name" :chip_number="(_list.indexOf(item)+1)" :chip_key="item.key" />
        </div>
      </draggable>

      <h4 id="title-color">Color de la interfaz</h4>
      <div class="toggle-box">
        <p id="dark-text" class="toggle-text">Oscuro</p>
        <Toggle :component_name="component_name_toggle" :input_id="input_id_toggle" @_emit_method_check_toggle="_getValueFromSwitch" />
      </div>
      <Button :button_name="button_name" :button_key="button_key" @_emit_method_button="_clickButtonSave" />
    </div>

    <div class="mdc-layout-grid__cell--span-1"></div>
  </div>
</template>



<script>
import Toggle from "@/components/helpers/Toggle.vue";
import Button from "@/components/helpers/Button.vue";
import Chips from "@/components/helpers/Chips.vue";
import {mapMutations } from "vuex";
// import axios from 'axios';

import draggable from 'vuedraggable';

export default {
  name: 'Preferences',
  components: {
    draggable,
    Toggle,
    Button,
    Chips,
  },
  data() {
    return {
      component_name_toggle: "toggle_preferences",
      label_name_toggle: "",
      input_id_toggle: "preferences-toggle",

      button_name: "Guardar",
      button_key: "save_preferences",

      chip_prueba: [
        {
          name: 'Riesgo',
          key: 'chip_a',
          chip_state: true,
          position: 1
        },
        {
          name: 'Tipo de entrada',
          key: 'chip_b',
          chip_state: true,
          position: 2
        },
        {
          name: 'Acciones',
          key: 'chip_c',
          chip_state: true,
          position: 3
        }
      ],
      chip_array: [
        {
          name: 'Rentabilidad',
          key: 'chip_d',
          chip_state: false,
          position: 0
        },
        {
          name: 'Montante',
          key: 'chip_e',
          chip_state: false,
          position: 1
        },
        {
          name: 'Siguiente LT',
          key: 'chip_f',
          chip_state: false,
          position: 2
        },
        {
          name: '1ª Fecha',
          key: 'chip_y',
          chip_state: false,
          position: 3
        },
        {
          name: 'IPO',
          key: 'chip_g',
          chip_state: false,
          position: 4
        },
        {
          name: 'Valor Actual',
          key: 'chip_h',
          chip_state: false,
          position: 5
        },
        {
          name: 'Valor de la divisa',
          key: 'chip_i',
          chip_state: true,
          position: 6
        },
        {
          name: 'Beneficio Ficticio',
          key: 'chip_j',
          chip_state: true,
          position: 7
        },
        {
          name: 'Beneficio Asegurado',
          key: 'chip_k',
          chip_state: false,
          position: 8
        },
        {
          name: 'Distancia de entrada',
          key: 'chip_l',
          chip_state: false,
          position: 9
        },
        {
          name: 'Fecha de entrada',
          key: 'chip_m',
          chip_state: false,
          position: 10
        },
        {
          name: 'Margen CFD',
          key: 'chip_n',
          chip_state: false,
          position: 11
        },
        {
          name: 'Comisión de entrada',
          key: 'chip_o',
          chip_state: false,
          position: 12
        },
        {
          name: 'Resistencia',
          key: 'chip_p',
          chip_state: false,
          position: 13
        },
        {
          name: 'Variación Actual',
          key: 'chip_q',
          chip_state: false,
          position: 14
        },
      ],

      portfolio_preferences: {},
    };
  },
  computed: {
    _list() {
      return this.chip_array
    }
  },
  mounted() {
    // this.getPortfolioPreferences();
  },
  methods: {
    _getValueFromSwitch(value) {
      this.switchTheme();
    },

    _clickButtonSave(value) {
    },

    _chipValue(value) {
      var objIndex = this.chip_array.findIndex((obj => obj.key == value[1]))

      this.chip_array[objIndex + 1].chip_state = value[0]
    },

    handleChange() {
     
      this.chip_array.forEach(function(element, index) {
        element.position = index + 1
        
      })
      
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        on: {
          end: this.handleChange,
          input: this.inputChanged
        },
        attrs:{
          wrap: true
        },
        props: {
          value: this.activeNames
        }
      };
    },
    // getPortfolioPreferences: function() {
    //   axios.get(process.env.VUE_APP_API + '/api/v1/portfolio-invest-preferences')
    //     .then(
    //       response => {
    //         this.portfolio_preferences = response.data;;
    //       },
    //       error => {
    //         console.error(error);
    //       }
    //     );
    // },
    ...mapMutations(['switchtheme']),
    switchTheme() {
      this.switchtheme();
    },
  }
}
</script>



<style lang="scss" scoped>
  h3 {
    margin: 0;
    padding: 80px 0 35px;
  }

  h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #00000B;
    background-color: #EDF8FB;
    padding: 12px 26px;
    margin: 0;
  }

  .title-margin {
    margin-bottom: 40px;
  }

  .chips-box {
    display: flex;
    flex-wrap: wrap;
  }

  .toggle-box {
    display: flex;
    align-items: center;
    margin: 24px 0 60px;
  }

  .toggle-text {
    margin-right: 14px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #00000B;
  }
  
  .chips-box-bottom {
    margin-bottom: 40px;
  }

  .ghost {
    opacity: 0.5;
  }

  div[disabled=disabled] {
    pointer-events: none;
  }

  .list-group-item {
    width: auto;
  }

</style>
